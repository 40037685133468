<swiper
  #swiper
  [config]="swiperConfig"
>
  <ng-template swiperSlide>
    <div class="modal-container step1">
      <div class="modal-header">
        <h3>Welcome to Toro Timer 👋🏼</h3>
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close()"></p-button>
      </div>
      <div class="img-block">
        <img src="./assets/images/onboarding/onboarding.svg" alt="Welcome to Toro Timer 👋🏼">
      </div>
      <div class="modal-text">
        <!-- <ul class="modal-step">
          <li class="active"><span>Step 1</span></li>
          <li (click)="goToStep(2)"><span>Step 2</span></li>
          <li><span>Step 2</span></li>
          <li><span>Step 3</span></li>
          <li><span>Step 4</span></li>
          <li><span>Step 5</span></li>
          <li><span>Step 6</span></li>
        </ul> -->
        <div class="modal-content">
          <h3>Welcome to Toro Timer 👋🏼</h3>
          <p>Hey there! Welcome to Toro, a time tracking tool that helps maximise your productivity by giving you insight on how you work.</p>
          <p>Click through our guide and let's get started!</p>
          <!-- <div class="p-field-checkbox">
            <div class="p-field-checkbox">
              <p-checkbox
                [binary]="true"
                inputId="binary"
                (onChange)="dontShowAnymore($event.checked)"
              ></p-checkbox>
              <label for="binary">Don't show this to me again</label>
            </div>
          </div> -->
        </div>
        <div class="modal-footer">
          <a class="btn-skip" (click)="close()">Skip Now</a>
          <a class="btn-sd btn-md btn-green" (click)="nextStep()">Next</a>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="modal-container step2">
      <div class="modal-header">
        <h3>Let's track your first task ⏱</h3>
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close()"></p-button>
      </div>
      <div class="img-block">
        <img class="mobile" src="./assets/images/onboarding/mobile-step2.gif" alt="Let's track your first task ⏱">
        <img class="desktop" src="./assets/images/onboarding/desktop-step2.gif" alt="Let's track your first task ⏱">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>Let's track your first task ⏱</h3>
          <p>Toro Timer helps to track the time you spend on your daily tasks.</p>
          <p>Name what you're tracking, select the Project, Task and if it's billable time, then click Start.</p>
        </div>
        <div class="modal-footer">
          <!--<a class="btn-skip" (click)="close()">Skip Now</a>-->
          <a class="btn-sd btn-md btn-ghost btn-ghost-green btn-prev" (click)="prevStep()">Previous</a>
          <a class="btn-sd btn-md btn-green" (click)="nextStep()">Next</a>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="modal-container step3">
      <div class="modal-header">
        <h3>Today's progress 📋</h3>
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close()"></p-button>
      </div>
      <div class="img-block">
        <img class="mobile" src="./assets/images/onboarding/mobile-step3.gif" alt="Today's progress 📋">
        <img class="desktop" src="./assets/images/onboarding/desktop-step3.gif" alt="Today's progress 📋">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>Today's progress 📋</h3>
          <p>See how your day has been going so far, track it with team members or solo, and see how you're faring compared to your previous days.</p>
        </div>
        <div class="modal-footer">
          <!--<a class="btn-skip" (click)="close()">Skip Now</a>-->
          <a class="btn-sd btn-md btn-ghost btn-ghost-green btn-prev" (click)="prevStep()">Previous</a>
          <a class="btn-sd btn-md btn-green" (click)="nextStep()">Next</a>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="modal-container step4">
      <div class="modal-header">
        <h3>Recent time tracked ⚡</h3>
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close()"></p-button>
      </div>
      <div class="img-block">
        <img class="mobile" src="./assets/images/onboarding/mobile-step4.gif" alt="Recent time tracked ⚡">
        <img class="desktop" src="./assets/images/onboarding/desktop-step4.gif" alt="Recent time tracked ⚡">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>Recent time tracked ⚡</h3>
          <p>You will have a clear overview of your recently tracked time. Toggle between a timeline or a list view by clicking on either of these icons.</p>
        </div>
        <div class="modal-footer">
          <!--<a class="btn-skip" (click)="close()">Skip Now</a>-->
          <a class="btn-sd btn-md btn-ghost btn-ghost-green btn-prev" (click)="prevStep()">Previous</a>
          <a class="btn-sd btn-md btn-green" (click)="nextStep()">Next</a>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="modal-container step5">
      <div class="modal-header">
        <h3>History 📊</h3>
        <p-button styleClass="btn-sd btn-sm btn-ghost btn-close" icon="pi pi-times" (click)="close()"></p-button>
      </div>
      <div class="img-block">
        <img class="mobile" src="./assets/images/onboarding/mobile-step5.gif" alt="History 📊">
        <img class="desktop" src="./assets/images/onboarding/desktop-step3.gif" alt="History 📊">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>History 📊</h3>
          <p>Looking for a particular time tracked? Simply choose the aspects you want to filter by and find what you're looking for in no time at all.</p>
        </div>
        <div class="modal-footer">
          <!--<a class="btn-skip" (click)="close()">Skip Now</a>-->
          <a class="btn-sd btn-md btn-ghost btn-ghost-green btn-prev" (click)="prevStep()">Previous</a>
          <a class="btn-sd btn-md btn-green" (click)="nextStep()">Next</a>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template swiperSlide>
    <div class="modal-container step6">
      <div class="modal-header">
        <h3>Woohoo that's it! 👏🏼</h3>
      </div>
      <div class="img-block">
        <img src="./assets/images/onboarding/end.svg" alt="Woohoo that's it! 👏🏼">
      </div>
      <div class="modal-text">
        <div class="modal-content">
          <h3>Woohoo that's it! 👏🏼</h3>
          <p>We hope these tips have helped, but if you still have any questions, hop over to our support page or get in touch with us and we'll make sure to clear things up for you too.</p>
          <p>Happy time tracking with Toro!</p>
        </div>
        <div class="modal-footer justify-center">
          <a class="btn-sd btn-md btn-green" (click)="close(); dontShowAnymore(true);">Start time tracking</a>
        </div>
      </div>
    </div>
  </ng-template>
</swiper>










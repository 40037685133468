import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SwiperModule } from 'swiper/angular';
import { DialogOnboardingComponent } from './dialog-onboarding/dialog-onboarding.component';
import { CheckboxModule } from 'primeng/checkbox';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [
    DialogOnboardingComponent
  ],
  imports: [
    CommonModule,
    CheckboxModule,
    ButtonModule,
    SwiperModule
  ]
})
export class OnboardingModule { }

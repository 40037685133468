export const environment = {
  id: 'staging',
  production: false,
  appBaseUrl: 'https://app.torostampede.com',
  apiUrl: 'https://api.torostampede.com/api/',
  socketIoServerUrl: 'https://api.torostampede.com',
  rollbarAccessToken: 'a3fc7329b31440a786f832347ebbcaa6',
  stripePublishableApiKey: 'pk_test_51GVrdYAxjCXla5Rf7onjRbGZCZQsRF3VmTNfcAK7cQpLPldMephFmrsv1KutCCjNimrol77Z3pz5zcCPzr0qs93X00LhsWrlT4',
  stripeProductIdToroMonthly: 'price_1Jar9UAxjCXla5RfKKnDI3UU',
  stripeProductIdToroAnnually: 'price_1Jar9UAxjCXla5RfW7wsEALk',
  enableMixpanelTracking: true
};

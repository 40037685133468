import { Inject, Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpResponse
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { catchError, tap } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { RollbarService } from 'src/app/services/rollbar.config';
import * as Rollbar from 'rollbar';

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {

  private API_BASE = environment.apiUrl;
  
  constructor(
    private userService: UserService,
    private router: Router,
    @Inject(RollbarService) private rollbar: Rollbar
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    return next.handle(request).
      pipe(
        tap(httpEvent => {

          if (httpEvent instanceof HttpResponse) {
            if(httpEvent.body?.status == 'NOT OK') {
              let user = JSON.parse( localStorage.getItem('user') || '{}' );

              if (Object.keys(user).length > 0) {
                //TODO: In the future all API calls should be appended with the latest user object so that we can always maintain the correct app state based on the latest user data.
                //refresh user profile in case we get any error caused by permission in order to update the UI based on the latest user
                this.userService.getUserFullProfile().subscribe();
              }
            }
          }

        }),
        catchError((err: HttpErrorResponse) => {

          if(environment.id != 'local') {

            let user = JSON.parse( localStorage.getItem('user') || '{}' );
            
            const rollbarConfig: Rollbar.Configuration = {
              payload: {
                person: {
                  id: user?.id,
                  email: user?.email
                },
                custom: {
                  active_workspace_id: user?.active_workspace_id,
                  active_workspace_userlevel_id: user?.active_workspace_userlevel_id
                }
              }
            };

            this.rollbar.configure(rollbarConfig);

            //log this error into Rollbar
            this.rollbar.error(err);
          }

          let message = (err.error?.message)? err.error.message : err.statusText;

          if(request.url.includes(this.API_BASE)) {

            if(err.status == 401) {
              this.userService.logout();
              // this.router.navigateByUrl('/auth/login');
              window.location.href = '/';
              //message = 'Unauthorized';
            }            
            
          }
          
          return throwError(message);

        })
      );

  }

}
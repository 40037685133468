import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';

import { User } from '../models/user';
import { LoginApiResponse, GenericApiResponse } from '../models/genericApiResponse';
import { delay, map, mergeMap } from 'rxjs/operators';
import { tr } from 'date-fns/locale';
import { Router } from '@angular/router';
import { WebsocketService } from './websocket.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private API_BASE = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private webSocketService: WebsocketService
  ) { }

  private invalidWorkspaceErrorSubject = new BehaviorSubject<boolean>(false);

	invalidWorkspaceError = this.invalidWorkspaceErrorSubject.asObservable();

  private reloadUserSubject = new BehaviorSubject<boolean>(false);

	reloadUser = this.reloadUserSubject.asObservable();

  triggerReloadUser() {
    this.reloadUserSubject.next(true);
  }

  /**
   * Login the user to Toro.
   * @param email User email
   * @param password User password
   */
  login(email: string, password: string): Observable<User> {

    let data = {
			'email': email,
			'password': password,
		};

		return this.http.post<LoginApiResponse>(this.API_BASE + `login`, data)
      .pipe(
        mergeMap(res => {

          if(res.status == 'OK') {
            //successful login

            localStorage.setItem('token', res.token);

            return this.getUserFullProfile(false);

          } else {
            //remove user and access code storage

						this.logout();

						throw new Error(res.status);

          }
            
        }),
        delay(500)
      );

  }

  /**
   * Remove the user and access token objects from localstorage.
   */
  logout() {

    this.webSocketService.disconnect();
    
    localStorage.removeItem('token');

    localStorage.removeItem('user');

    localStorage.removeItem('insights_collapsed')

  }

  /**
   * Initiate forgot password request to receive a reset password email.
   */
  forgotPassword(email: string): Observable<boolean> {

    let data = {
			'email': email
		};

		return this.http.post<GenericApiResponse>(this.API_BASE + `forgotpassword/email`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful request

            return true;

          } else {
            //error in request

						throw new Error(res.message);

          }
            
        })
      );

  }

  /**
   * Submit new password via the password reset form.
   */
  resetPassword(email: string, password: string, token: string): Observable<boolean> {

    let data = {
			'email': email,
			'password': password,
      'token': token
		};

		return this.http.post<GenericApiResponse>(this.API_BASE + `forgotpassword/reset`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful request

            return true;

          } else {
            //error in request

						throw new Error(res.message);

          }
            
        })
      );

  }

  /**
   * Register a new user account and auto login the user using the token received in the response.
   */
  register(data: object): Observable<User> {

		return this.http.post<LoginApiResponse>(this.API_BASE + `register`, data)
      .pipe(
        mergeMap(res => {

          if(res.status == 'OK') {
            //successful request

            localStorage.setItem('token', res.token);

            return this.getUserFullProfile();

          } else {
            //error in request

            return throwError(res.message)

          }
            
        })
      );

  }

  /** 
   * Get full user profile including subscriptions and workspace participations details.
   */
  getUserFullProfile(broadcastReloadedUserData: boolean = true): Observable<User>  {

    return this.http.get<GenericApiResponse>(this.API_BASE + `user/profile`)
      .pipe(
        map(res => {
          // console.log('user profile', res);
          
          if(res.status == 'OK') {
            return res.data;
          } else {
						throw new Error(res.status);
          }
            
        }),
        map((user:User) => {

          //get user active workspace info
          let active_workspace = user.workspaces.find(ws => {
            return ws.id == user.active_workspace_id;
          });

          user.active_workspace_owner = false;

          if(active_workspace) {

            user.active_workspace_name = active_workspace.name;

            //set the user level in the active workspace
            user.active_workspace_userlevel_id = active_workspace.pivot.userlevel_id;

            //set the user workspace ownership status in the active workspace
            if(active_workspace.created_by == user.id) {
              user.active_workspace_owner = true;
            }

            user.active_workspace_jobrole = active_workspace.pivot.jobrole;
            user.active_workspace_location = active_workspace.pivot.location;

          } else {
            //no active workspace, redirect to workspace listing
            this.router.navigateByUrl('/workspaces');
            this.invalidWorkspaceErrorSubject.next(true);
          }

          localStorage.setItem('user', JSON.stringify(user));

          if(broadcastReloadedUserData) this.triggerReloadUser();
          
          return user;
        })
      );

  }

  /**
   * Update the user profile information.
   * @param data User profile data
   */
  updateProfile(userdata: any): Observable<boolean> {

		return this.http.post<GenericApiResponse>(this.API_BASE + `user/profile`, userdata)
      .pipe(
        mergeMap(res => {

          if(res.status == 'OK') {
            //successful change

            return this.getUserFullProfile();

          } else {

            throw new Error(res.message);
            
          }
            
        }),
        map(() => {
          return true;
        })
      );

  }
  
  /**
   * Update the user's email to login into Toro.
   * @param email User new email
   */
  updateEmail(email: string): Observable<boolean> {

    let data = {
			'email': email
		};

		return this.http.post<GenericApiResponse>(this.API_BASE + `user/email`, data)
      .pipe(
        mergeMap(res => {

          if(res.status == 'OK') {
            //successful change

            return this.getUserFullProfile();

          } else {

            throw new Error(res.message);
            
          }
            
        }),
        map(() => {
          return true;
        })
      );

  }

  /**
   * Update user's password to login into Toro.
   * @param oldpassword User existing password
   * @param newpassword User new password
   */
  changePassword(oldpassword: string, newpassword: string): Observable<boolean> {

    let data = {
			'current_password': oldpassword,
			'new_password': newpassword,
			'new_password_confirmation': newpassword
		};

		return this.http.post<GenericApiResponse>(this.API_BASE + `user/password`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful change

            return true;

          } else {

            throw new Error(res.message);
            
          }
            
        })
      );

  }

  /**
   * Switch to another workspace.
   */
   switchWorkspace(id: number): Observable<boolean> {

    let data = {
      workspace_id: id
    };

    return this.http.post<GenericApiResponse>(this.API_BASE + `user/workspace`, data)
      .pipe(
        map(res => {

          if(res.status == 'OK') {
            //successful change

            return true;

          } else {

            throw new Error(res.message);
            
          }
            
        }),
        mergeMap(data => {
          return this.getUserFullProfile(false);
        }),
        map(data => {
          return true;
        })
      );

  }

}


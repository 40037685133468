import { Component, OnInit, ChangeDetectionStrategy, ViewChild } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import SwiperCore, { SwiperOptions, Pagination } from 'swiper';
import { SwiperComponent } from 'swiper/angular';

// install Swiper modules
SwiperCore.use([
  Pagination,
]);

@Component({
  selector: 'app-dialog-onboarding',
  templateUrl: './dialog-onboarding.component.html',
  styleUrls: ['./dialog-onboarding.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogOnboardingComponent implements OnInit {

  constructor(
    private ref: DynamicDialogRef,
    private userService: UserService,
  ) { }

  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  swiperConfig: SwiperOptions = {
    slidesPerView: 1,
    pagination: true,
    allowTouchMove: true,
    autoHeight: true,
    breakpoints: {
      768: {   // when window width is >= 768px
        allowTouchMove: false
      }
    }
  };

  currentStep = 1

  checkboxSubject = new Subject<boolean>();
  checkboxSubjectSubscription!: Subscription;

  ngOnInit(): void {

    //save hide_onboarding user data
    this.checkboxSubject
      .pipe(
        debounceTime(250),
        switchMap(val => {

          let data = {
            'settings': {
              'hide_onboarding': (val)? '1' : '0'
            }
          };

          return this.userService.updateProfile(data);

        })
      )
      .subscribe(
        data => {
        },
        err => {
        }
      );

  }

  close(state: boolean = true) {
    this.ref.close(state);
  }

  dontShowAnymore(status: boolean) {
    this.checkboxSubject.next(status);
  }

  nextStep() {
    this.currentStep = this.currentStep + 1;
    this.swiper?.swiperRef.slideNext();
  }

  prevStep() {
    this.currentStep = this.currentStep - 1;
    this.swiper?.swiperRef.slidePrev();
  }

  // goToStep(index = 0) {
  //   this.currentStep = index;
  // }

  // close() {
  //   this.ref.close();
  // }

  ngOnDestroy() {
    this.checkboxSubjectSubscription?.unsubscribe();
  }

}

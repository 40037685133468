import { Injectable } from '@angular/core';
import { io, Socket } from "socket.io-client";
import { environment } from 'src/environments/environment';
import { User } from '../models/user';
import { TimerService } from './timer.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  readonly socket: Socket = io(
    environment.socketIoServerUrl, 
      { 
      transports: ["websocket"],
      autoConnect: false
    }
  );

  constructor(
    private timerService: TimerService
  ) { }

  /** Connect to the SocketIO server and initialize the SocketIO event listeners. */
  connect() {

    const user: User = JSON.parse( localStorage.getItem('user') || '{}' );
    
    // console.log(user);

    if(user) {
        
      //connect to SocketIo server
      this.socket.connect();

      this.socket.on('connect', () => {
        console.log('Connected!', this.socket.id);
      });

      this.socket.on(`private-gcal-${user.id}:App\\Events\\SyncGoogleCalendarEvent`, (events) => {
        console.log('Sync', events);
        this.timerService.reloadRecentTimeTrackingData();
      });

    }

  }

  /** Disconnect from the SocketIO server. */
  disconnect() {
    this.socket.disconnect();
  }
  
}
